<template>
    <mercur-card v-if="isAllowedTo('SupplierCentral/countOrdersBySupplierAndStatus', 'SupplierCentral/getSuppliersByParentId')" class="fill full-height-layout mx-4 mb-3">
        <grid-header
            :quickSearch.sync="filters.search"
            data-test="supplierTableHeader"
        >
            Suppliers
            <template slot="actions">
                <mercur-button
                    v-if="isAllowedTo('SupplierCentral/createSupplier')"
                    @click='supplierPopupActive = true'
                    class="btn btn-raised btn-yellow text-uppercase">
                    <i class="fas fa-plus"></i>
                    <span>Add new supplier</span>
                </mercur-button>
            </template>
        </grid-header>

        <data-table
            class="fill full-height-layout border"
            :options="options"
            :items="items"
            :quickSearch="filters.search"
            data-test="supplierTableData"
        ></data-table>
        <mercur-dialog :is-open.sync="supplierPopupActive">
            <supplier-add
                :close-action="closeSupplierAddPopup"
                :save-action="saveSupplierAddPopup"
            />
        </mercur-dialog>
        <mercur-dialog :is-open.sync="orderIdentifierSearchPopupActive">
            <div slot="header"><h2 class="font-weight-normal">Search for order</h2></div>
            <div slot="default">
                <form @submit.prevent="orderIdentifierSearchSubmit">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <mercur-input v-model="orderIdentifierSearchQuery">
                                Order line number / id
                            </mercur-input>
                        </div>
                        <div class="col-4">
                            <mercur-button
                                type="submit"
                                class="btn btn-raised btn-yellow text-uppercase"
                                :disabled="orderIdentifierSearchLoading">
                                <i class="fas fa-search"></i>
                                Search
                            </mercur-button>
                        </div>
                        <div class="col-12">
                            <div v-if="orderIdentifierSearchResult">
                                <template v-if="orderIdentifierSearchResult.supplier">
                                    Supplier:
                                    <router-link :to="getSupplierRoute(orderIdentifierSearchResult.supplier.supplierId)">
                                        {{orderIdentifierSearchResult.supplier.supplierName}}
                                    </router-link>
                                    <div v-if="getOrderLineDetailRoute(orderIdentifierSearchResult)">
                                        OrderLine:
                                        <router-link :to="getOrderLineDetailRoute(orderIdentifierSearchResult)">
                                            <small>{{orderIdentifierSearchResult.orderLine.orderId}} - {{orderIdentifierSearchResult.orderLine.orderLineId}}</small>
                                        </router-link>
                                        <br />
                                        <mercur-button
                                            class="btn btn-raised btn-yellow text-uppercase"
                                            :to="getOrderLineDetailRoute(orderIdentifierSearchResult)">
                                            Go to order line
                                        </mercur-button>
                                    </div>
                                    <div v-else>
                                        Order line id: <kbd>{{orderIdentifierSearchResult.orderLine.orderLineId}}</kbd><br />
                                        Order id: {{orderIdentifierSearchResult.orderLine.orderId}}<br />
                                        Order line status:
                                        <strong>{{orderIdentifierSearchResult.orderLine.orderLineStatus | beautify | lowerCase | upperCaseFirst}}</strong>.
                                        <br />
                                        <br />
                                        There is no supplier order line overview for this order line status.
                                    </div>
                                    <mercur-button class="btn btn-raised text-uppercase" :to="getSupplierRoute(orderIdentifierSearchResult.supplier.supplierId)">Go to supplier</mercur-button>
                                </template>
                                <template v-else>
                                    <i>No supplier found.</i>
                                </template>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div slot="footer">
                <mercur-button class="btn btn-raised text-uppercase" @click="orderIdentifierSearchPopupActive = false">Close</mercur-button>
            </div>
            <mercur-progress-bar indeterminate v-if="orderIdentifierSearchLoading"></mercur-progress-bar>
        </mercur-dialog>
    </mercur-card>
    <div v-else>
        <p class="permission-message">Not allowed to see this view</p>
    </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import SupplierAdd from '@/components/SupplierAdd'
import CONFIG from '@root/config'
import GridHeader from '@/components/GridHeader'

export default {
    name: 'SupplierOverview',
    components: { DataTable, SupplierAdd, GridHeader },
    data () {
        const statusColumns = CONFIG.STATUSSES.SUPPLIERS.ORDERLINES.filter(({ rawValue }) => rawValue)
        return {
            options: {
                columns: {
                    'Name': {
                        field: 'supplierName',
                        sortable: true,
                        link: (value, data) => {
                            return {
                                name: 'SupplierProducts',
                                params: {
                                    supplierId: data.supplierId,
                                },
                            }
                        },

                    },
                    ...statusColumns.reduce((columns, status) => {
                        columns[`${status.title} orders`] = {
                            field: `statuses.${status.rawValue}`,
                            sortable: true,
                            link: (value, data) => {
                                return {
                                    name: 'SupplierOrderlines',
                                    params: {
                                        supplierId: data.supplierId,
                                        status: status.value,
                                    },
                                }
                            },
                        }
                        return columns
                    }, {}),

                    'Created': {
                        field: 'dateCreated',
                        sortable: true,
                    },
                },
                actions: [
                    {
                        icon: 'fas fa-eye',
                        text: 'Products',
                        tooltipText: 'View products',
                        to: params => ({
                            name: 'SupplierProducts',
                            params: {
                                supplierId: params.data.supplierId,
                            },
                        }),
                    },
                ],

                quickSearchColumns: ['supplierName'],
            },
            items: null,
            filters: {
                search: '',
            },
            supplierPopupActive: false,
            orderIdentifierSearchPopupActive: false,
            orderIdentifierSearchQuery: null,
            orderIdentifierSearchResult: null,
            orderIdentifierSearchLoading: false,
        }
    },

    methods: {
        getSupplierRoute (supplierId) {
            return {
                name: 'SupplierOrderlines',
                params: {
                    supplierId,
                    status: 'approved',
                },
            }
        },
        getOrderLineDetailRoute ({ supplier, orderLine }) {
            const status = CONFIG.STATUSSES.SUPPLIERS.ORDERLINES.find(({ rawValues }) => rawValues.includes(orderLine.orderLineStatus))
            if (!status) {
                return null
            }
            return {
                name: 'OrderLineDetail',
                params: {
                    supplierId: supplier.supplierId,
                    status: status.value,
                    orderId: orderLine.orderId,
                    orderLineId: orderLine.orderLineId,
                },
            }
        },
        orderIdentifierSearchSubmit () {
            this.orderIdentifierSearchLoading = true
            const url = CONFIG.API.ROUTES.SUPPLIERS.GET_BY_ORDER_LINE_IDENTIFIER
            this.addJob(url)
            const payload = {
                orderLineIdentifier: this.orderIdentifierSearchQuery,
            }
            this.$api.post(url, payload).then(({ data }) => {
                this.$set(this, 'orderIdentifierSearchResult', data.data)
            }).catch(data => {
                this.$root.$emit('notification:global', {
                    message: `Getting supplier by order line identifier failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.orderIdentifierSearchLoading = false
                this.finishJob(url)
            })
        },
        closeSupplierAddPopup () {
            this.supplierPopupActive = false
        },

        saveSupplierAddPopup (response, values) {
            this.supplierPopupActive = false
            this.$router.push({
                name: 'SupplierOnboardView',
                params: {
                    supplierId: response.data.supplierId,
                    ...values,
                },
            })
        },

        getSupplierEditLink (supplier) {
            return {
                name: 'SupplierOnboardView',
                params: {
                    supplierId: supplier.supplierId,
                },
            }
        },
    },
    created () {
        const url = CONFIG.API.ROUTES.SUPPLIERS.OVERVIEW
        this.addJob(url)
        this.$api.post(url, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.items = data.data.items
        }).catch(data => {
            this.$root.$emit('notification:global', {
                message: `Getting suppliers failed. Please try again.`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.finishJob(url)
        })
    },

    mounted () {
        if (this.$route.query.openModal === 'addSupplier') {
            this.supplierPopupActive = true
        }
    },
}
</script>
